import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  productForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddProductComponent>,
  ) { }

  ngOnInit(): void {
    this.productForm = this.fb.group({
      ean: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
    });
  }

  close() {
    this.dialogRef.close();
  }

  addProduct() {
    if (this.productForm.valid) {
      const productArray = {
        amount: this.productForm.value.amount,
        ean: this.productForm.value.ean,
      };
      this.dialogRef.close(productArray);
    }
  }
}
