<div class="wrapper">
  <mat-card>
    <mat-card-content>
      <form fxLayout="column" [formGroup]="apiForm">
        <small (click)="showFullUrl()">Hele url zien?</small>
        <mat-form-field appearance="outline">
          <mat-label>Request URL</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder=""
            formControlName="requestUrl"
            readonly
          />
        </mat-form-field>
        <mat-radio-group fxLayout="column" formControlName="type">
          <p>Ik wil:</p>
          <mat-radio-button value="getCoupon" default
            >Een bon ophalen</mat-radio-button
          >
          <mat-radio-button value="claimCoupon"
            >Een bon claimen</mat-radio-button
          >
          <mat-radio-button value="getOrganisations"
            >Ondernemers ophalen</mat-radio-button
          >
        </mat-radio-group>
        <mat-form-field appearance="outline" *ngIf="showCouponField">
          <mat-label>Boncode (voucherCode)</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder=""
            formControlName="voucherCode"
            autocomplete="off"
            required
          />
          <mat-error>
            {{ getError("voucherCode") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>API key</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder=""
            formControlName="apiKey"
            autocomplete="off"
            required
          />
          <mat-error>
            {{ getError("apiKey") }}
          </mat-error>
        </mat-form-field>

        <div
          class="claim"
          *ngIf="
            apiForm.value.apiKey &&
            apiForm.value.voucherCode &&
            apiForm.value.type === 'claimCoupon'
          "
        >
          <p>Claim parameters</p>
          <mat-form-field appearance="outline">
            <mat-label>Bedrag</mat-label>
            <input
              matInput
              autocomplete="off"
              placeholder=""
              autocomplete="off"
              formControlName="amount"
              type="number"
            />
          </mat-form-field>
          <button
            mat-raised-button
            (click)="addProduct()"
            color="primary"
            [disabled]="!amountChoosen"
          >
            Voeg product toe
          </button>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="column">
      <button
        mat-raised-button
        color="primary"
        (click)="sendRequest()"
        [disabled]="!apiForm.valid || isSending"
        [class.spinner]="isSending"
      >
        VERSTUREN
      </button>
      <!-- <button
        mat-raised-button
        color="primary"
        (click)="updateVoucher()"
        [disabled]="!apiForm.valid"
        [class.spinner]="loadingSpinnerClaim"
      >
        CLAIMEN (PUT)
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="getVoucher()"
        [disabled]="!apiForm.valid"
        [class.spinner]="loadingSpinnerGet"
      >
        OPHALEN VOUCHER(GET)
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="getOrganisations()"
        [disabled]="!apiForm.valid"
        [class.spinner]="loadingSpinnerGet"
      >
        OPHALEN ONDERNEMERS(GET)
      </button> -->
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="apiResult">
    <mat-card-content>
      <textarea readonly
        >{{ apiResult | json }}
            </textarea
      >
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="postData && apiForm.value.type === 'claimCoupon'">
    <mat-card-content>
      <textarea readonly
        >{{ postData | json }}
            </textarea
      >
    </mat-card-content>
  </mat-card>
</div>
