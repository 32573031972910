<div class="wrapper">
  <form fxLayout="column" [formGroup]="productForm">
    <mat-form-field appearance="outline">
      <mat-label>EAN nummer</mat-label>
      <input
        matInput
        autocomplete="off"
        placeholder=""
        formControlName="ean"
        autocomplete="off"
        type="number"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Aantal</mat-label>
      <input
        matInput
        autocomplete="off"
        placeholder=""
        formControlName="amount"
        autocomplete="off"
        type="number"
      />
    </mat-form-field>
  </form>
  <button
    mat-raised-button
    (click)="addProduct()"
    [disabled]="!productForm.valid"
    color="primary"
  >
    VOEG TOE
  </button>
  <button mat-raised-button (click)="close()">ANNULEER</button>
</div>
