import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AddProductComponent } from '../dialogs/add-product/add-product.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  apiForm: FormGroup;
  apiResult: {};
  postData: {} = {};

  env = environment;

  rootUrl = environment.functionsUrl;
  baseUrl = '/api/v1/vouchers/{voucherCode}/claim'; // https://europe-west1-wemaron-vouchers-dev.cloudfunctions.net/
  newUrl = this.baseUrl;
  getOrganisationsUrl = '/api/v1/statistics';
  amountChoosen: boolean;
  products: any[] = [];

  isSending: boolean = false;

  showCouponField: boolean = true;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.apiForm = this.fb.group({
      requestUrl: new FormControl('', [Validators.required]),
      voucherCode: new FormControl(''),
      apiKey: new FormControl('', [Validators.required]),
      amount: new FormControl(''),
      products: new FormControl(''),
      type: new FormControl('getCoupon', [Validators.required]),
    });

    this.apiForm.controls.requestUrl.setValue(this.baseUrl);
    this.apiForm.controls.voucherCode.valueChanges
      .pipe(debounceTime(500))
      .subscribe((code) => {
        if (code === '') {
          this.newUrl = this.baseUrl;
        } else {
          this.newUrl = this.baseUrl.replace('{voucherCode}', `${code}`);
        }
        if (this.apiForm.controls.type.value !== 'getOrganisations') {
          this.apiForm.controls.requestUrl.setValue(this.newUrl);
        }
      });

    this.apiForm.controls.amount.valueChanges
      .pipe(debounceTime(500))
      .subscribe((amount) => {
        //   this.newUrl = this.baseUrl += `?code=${apiKey}`;
        //   this.apiForm.controls.requestUrl.setValue(this.newUrl);
        if (amount !== '') {
          this.amountChoosen = true;
          this.postData = {
            amount: amount,
          };
        } else {
          this.amountChoosen = false;
          this.postData = {
            amount: amount,
            products: this.products,
          };
        }
      });

    this.apiForm.controls.type.valueChanges.subscribe((value) => {
      if (value !== 'getOrganisations') {
        this.showCouponField = true;
        // this.apiForm.controls.requestUrl.setValue(
        //   (this.baseUrl = '/api/v1/vouchers/{voucherCode}/claim')
        // );
        this.apiForm.controls.requestUrl.setValue(this.newUrl);
        this.apiForm.controls.voucherCode.setValidators([Validators.required]);
        this.apiForm.updateValueAndValidity();
      } else {
        this.showCouponField = false;
        this.apiForm.controls.voucherCode.clearValidators();
        this.apiForm.controls.voucherCode.updateValueAndValidity();
        this.apiForm.controls.requestUrl.setValue(this.getOrganisationsUrl);
      }
    });
  }
  getError(name) {
    const field = this.apiForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }

  showFullUrl() {
    const requestUrl = this.rootUrl + this.apiForm.value.requestUrl;
    alert(requestUrl);
  }

  clear() {
    this.apiForm.reset();
    this.apiForm.controls.requestUrl.setValue(this.baseUrl);
  }

  addProduct() {
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: '350px',
      disableClose: true,
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log('The dialog was closed', result);
        // this.animal = result;
        console.log('this.products', this.products);
        this.products.push(result);

        this.products = [
          ...new Map(this.products.map((item) => [item['ean'], item])).values(),
        ];
        console.log('this.postData', this.postData);
        this.postData = {
          amount: this.apiForm.value.amount,
          products: this.products,
        };
      }
    });
  }

  async updateVoucher() {
    const requestUrl = this.rootUrl + this.apiForm.value.requestUrl;
    const apiKey = this.apiForm.value.apiKey;

    console.log('apiKey', apiKey);
    const header = new HttpHeaders()
      .set('Authorization', apiKey)
      .set('Access-Control-Allow-Origin', '*');
    // .set('Access-Control-Allow-Methods', 'PATCH');;

    let res: Observable<any>;
    console.log('req', requestUrl);
    // const postData = {
    //   amount: this.apiForm.value.amount,
    //   products: this.products,
    // }
    if (!this.products || !this.apiForm.value.amount) {
      this.snackbar.open(
        'Voor een claim request is het bedrag en een product nodig',
        'X',
        {
          duration: 4000,
        }
      );
      return;
    }
    res = this.http.put(requestUrl, this.postData, {
      headers: header,
    });
    res.subscribe((result) => {
      console.log('result', result);
      console.log('result', result);
      this.apiResult = result;
      this.isSending = false;
      // const elem = document.getElementById('json');
      // elem.innerHTML = prettyPrintJson.toHtml(result);
    });
  }

  async getVoucher() {
    const requestUrl = this.rootUrl + this.apiForm.value.requestUrl;
    const apiKey = this.apiForm.value.apiKey;

    if (!this.apiForm.controls.voucherCode.value) {
      this.snackbar.open('Een boncode is verplicht bij deze request', 'X', {
        duration: 5000,
      });
      return;
    }
    const header = new HttpHeaders().set('Authorization', apiKey);
    let res: Observable<any>;
    res = this.http.get(requestUrl, {
      headers: header,
      responseType: 'text',
    });
    res.subscribe((result) => {
      console.log('result', JSON.parse(result));
      this.apiResult = JSON.parse(result);
      this.isSending = false;
      // const elem = document.getElementById('json');
      // elem.innerHTML = prettyPrintJson.toHtml(result);
    });
  }

  async getOrganisations() {
    const requestUrl = this.rootUrl + '/api/v1/statistics';
    const apiKey = this.apiForm.value.apiKey;

    const header = new HttpHeaders().set('Authorization', apiKey);
    let res: Observable<any>;
    res = this.http.get(requestUrl, {
      headers: header,
      responseType: 'text',
    });
    res.subscribe((result) => {
      console.log('result', JSON.parse(result));
      this.apiResult = JSON.parse(result);
      this.isSending = false;
      // const elem = document.getElementById('json');
      // elem.innerHTML = prettyPrintJson.toHtml(result);
    });
  }

  async sendRequest() {
    const type = this.apiForm.controls.type.value;
    console.log('type', type);
    this.isSending = true;
    try {
      switch (type) {
        case 'getCoupon':
          await this.getVoucher();
          break;
        case 'claimCoupon':
          await this.updateVoucher();
          break;
        case 'getOrganisations':
          await this.getOrganisations();
          break;
        default:
          this.snackbar.open("Vul een keuze in bij 'Ik wil'", 'X', {
            duration: 5000,
          });
          break;
      }
      // this.isSending = false;
    } catch (e) {
      this.isSending = false;
    }
  }
}
